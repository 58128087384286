.formacion {
  border: 0px;

  display: grid;
  grid-template-columns: min(30%, 350px) calc(100vw - min(30%, 350px));
  grid-template-rows: 1fr;
  grid-template-areas: "menu content";
}

.menu {
  grid-area: menu;
  background-color: grey;
  background-image: url("../../assets/pattern.png");
  background-size: cover;
  background-position: left center;
  color: white;
  text-align: left;
  padding: 25px 0px;
}
.menu li {
  width: 100%;
  padding-right: 30px;
}
.menu li.selected {
  color: var(--yellow);
  font-family: segoe-black;
}

.content {
  grid-area: content;
  width: calc(100% - 20px);
  border-left: 10px solid var(--pink);
}
.content p {
  width: min(90%, 600px);
  margin: 25px auto;
  text-align: justify;
}

.video{
  width: min(90%, 520px);
  margin: 25px auto;
}
.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.content button {
  height: 25px;
  font-size: 16px;
  padding: 0px 30px;
  margin: 5px auto 25px;
}
.content .logo {
  width: 100px;
}
.content span {
  color: var(--pink);
  font-family: segoe-black;
}
.content div {
  width: min(90%, 600px);
  margin: 0px auto;
}
.content .button-left {
  margin: -15px 0px 25px;
  display: block;
}

@media (max-width: 700px) {
  .formacion {
    border: 0px;
  
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    grid-template-areas: "menu" "content";
  }

  .menu {
    background-color: var(--pink);
    color: white;
    text-align: center;
    padding: 5px 0px;
  }
  .menu li {
    width: 100%;
    padding-right: 0px;
    font-size: 18px;
    line-height: 1.1;
  }
  .menu li::before {
    color: white;
  }
  .menu li.selected {
    color: var(--yellow);
    font-family: segoe-black;
  }
  .menu li.selected::before {
    color: var(--yellow);
  }

  .menu li {
    width: calc(100% - 20px);
    padding-right: 0px;
  }
}
