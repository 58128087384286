.iframe-container {
    background-color: var(--black);
    padding-top: 25px;
}

.iframe-container iframe {
    height: calc(100vh - 199px);
}

.iframe-container h1 {
    margin-top: 0px;
}
