.home {
  min-height: calc(100vh - 450px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.carousel-cell {
  width: 100%;
  height: 350px;
  margin-right: 10px;
  background: grey;
  counter-increment: gallery-cell;

  display: grid;
  grid-template-columns: 1fr 1fr;
}
.empieza {
  background: url("../../assets/carousel/Desktop1.jpg");
  background-size: cover;
  background-position: left center;
}
.fechas {
  background: url("../../assets/carousel/Desktop3.jpg");
  background-size: cover;
  background-position: left center;
}
.dudas {
  background: url("../../assets/carousel/Desktop2.jpg");
  background-size: cover;
  background-position: left center;
}

.h1-carousel {
  padding: 0px 5px;
  margin: 25px auto;
  font-size: 38px;
}
.h1-carousel::before {
  z-index: -1;
  top: 55%;
  height: 45%;
  transform: skewX(0deg);
}

.carousel-cell p {
  color: white;
  width: 70%;
  margin: 20px auto;
  font-size: 18px;
}
.carousel-cell a {
  font-size: 20px;
}

.carousel-container {
  width: 100%;
  height: 80%;
  padding: 10px 50px;
  grid-column-start: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.fechas-container {
  width: fit-content;
  margin: 0px auto;
  background-color: var(--pink);
  text-align: left;
  color: white;
  padding: 10px;
  margin-top: -5px;
  margin-bottom: 15px;
}
.title-fecha {
  font-family: segoe-regular;
  margin-right: 2px;
}

.home-link-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 80%;
  margin-top: 80px;
}
.home-link {
  width: 48%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  column-gap: 25px;
  row-gap: 10px;
}
.home-logo {
  width: min(80%, 180px);
  margin: 0 auto;
}
.home-link-content {
  text-align: left;
}

@media (max-width: 1230px) {
  .carousel-cell {
    display: grid;
    grid-template-columns: 1fr;
  }

  .h1-carousel {
    padding: 0px 5px;
    margin: 10px auto;
    font-size: 28px;
  }
  .carousel-cell p {
    width: 60%;
    margin: 25px auto;
    font-size: 20px;
  }
}

@media (max-width: 900px) {
  .home-link {
    width: 100%;
    grid-template-columns: 1fr;
    row-gap: 0px;
    margin: 15px auto;
  }
  .home-logo {
    width: min(50%, 180px);
    margin: 0 auto;
  }
  .home-link-content {
    text-align: center;
  }
  .home-link-container {
    margin-top: 25px;
  }
}

@media (max-width: 600px) {
  .carousel-cell {
    display: grid;
    grid-template-columns: 1fr;
  }

  .h1-carousel {
    padding: 0px 5px;
    margin: 10px auto;
    font-size: 18px;
  }
  .carousel-cell p {
    width: 90%;
    margin: 10px auto;
    font-size: 16px;
  }

  .empieza {
    background: url("../../assets/carousel/Mobile1.jpg");
    background-size: cover;
    background-position: center center;
  }
  .fechas {
    background: url("../../assets/carousel/Mobile3.jpg");
    background-size: cover;
    background-position: center center;
  }
  .dudas {
    background: url("../../assets/carousel/Mobile2.jpg");
    background-size: cover;
    background-position: center center;
  }
}
