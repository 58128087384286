:root {
  --black: #191919;
  --gray: #efefef;
  --pink: #ff096c;
  --green: #34d0a1;
  --blue: #22aae4;
  --petrol: #187b9e;
  --yellow: #fccc00;
}

@font-face {
  font-family: segoe-light;
  src: url(fonts/segoeui.ttf);
  font-display: swap;
}
@font-face {
  font-family: segoe-regular;
  src: url(fonts/segoeuib.ttf);
  font-display: swap;
}
@font-face {
  font-family: segoe-black;
  src: url(fonts/seguibl.ttf);
  font-display: swap;
}

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: Segoe UI, segoe-light;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

h1, h2, h3 {
  display: table;
  position: relative;
  margin: 10px auto;
  color: white;
  text-transform: uppercase;
  font-family: segoe-black;
  line-height: 1.8;
  z-index: 1;
  padding: 0px 32px;
}
h1::before, h2::before, h3::before {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--pink);
  transform: skewX(-20deg);
}
h1 {
  margin: 25px auto 12px;
  font-size: 32px;
}
h3 {
  font-size: 24px;
}


button {
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  font-family: Segoe UI, segoe-light;
  height: 32px;
  font-size: 18px;
  background-color: var(--blue);
  border: none;
  padding: 5px 25px;
}
button:hover {
  font-family: segoe-black;
  cursor: pointer;
}
button.selected {
  font-family: segoe-black;
}
button:only-of-type {
  border-radius: 7px !important;
}
button:first-child:nth-last-child(n + 2) {
  padding-left: 45px;
  border-radius: 7px 0px 0px 7px;
}
button:last-child:nth-child(n + 2) {
  padding-right: 45px;
  border-radius: 0px 7px 7px 0px;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
ul li {
  display: inline-block;
  margin-left: 20px;
  padding: 5px 0px;
  font-family: Segoe UI, segoe-light;
  font-size: 25px;
}
ul li::before {
  content: '/';
  color: var(--pink);
}
ul li:hover {
  font-family: segoe-regular;
  cursor: pointer;
}

a {
  text-decoration: inherit;
  color: inherit;
}

iframe {
  width: 100%;
}

@media (max-width: 800px) {
  h1 {
    font-size: 22px;
  }
  h2 {
    font-size: 18px;
  }
  h3 {
    font-size: 16px;
  }

  button {
    font-size: 12px;
    padding: 5px 8px;
    height: 30px;
  }
  button:first-child:nth-last-child(n + 2) {
    padding-left: 15px;
  }
  button:last-child:nth-child(n + 2) {
    padding-right: 15px;
  }
}

.footer {
  width: min(80%, 400px);
  height: auto;
  margin-bottom: -1px;
}
